<template>
  <div>
    <v-card :loading='loading'>
      <v-card-title class='px-2'>
        <Comeback />
        {{ $t('ReportSummaryMovementsDrugEquipment') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          :loading='exportLoading'
          class='d-none d-md-block'
          :disabled='exportLoading'
          @click='exportExcel'
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color='primary'
          class='d-block d-md-none'
          :loading='exportLoading'
          :disabled='exportLoading'
          icon
          fab
          outlined
          @click='exportExcel'
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters :default-status='1' @onSendDate='addPayload'></DateFilters>
      <v-row class='px-2'>
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-select
            v-model='movementSelect'
            :items='movementList'
            :label="$t('status')"
            outlined
            @change='addPayload(payload.start, payload.end)'
            dense
            :no-data-text="$t('no_information')"
            :item-text='$i18n.locale'
            item-value='value'
          ></v-select>
        </v-col>

        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-text-field
            v-model='searchtext'
            dense
            outlined
            :label="`${$t('code')}, ${$t('name')} (${$t('drug_equipment')}) ${$t('pressEnterForSearch')}`"
            @keypress.enter='addPayload(payload.start, payload.end)'
          >
          </v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-text-field
            v-model='searchlot'
            dense
            outlined
            :label="`${$t('code')}, ${$t('name')} (${$t('lot')}) ${$t('pressEnterForSearch')}`"
            @keypress.enter='addPayload(payload.start, payload.end)'
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :loading='loading'
        disable-sort
        hide-default-header
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:header>
          <tr style='height: 43px'>
            <th rowspan='2' class='text-left pl-4' style='min-width: 80px'>#</th>
            <th rowspan='2' class='text-left pl-4' style='min-width: 110px'>
              {{ $t('drug_code') }}
            </th>
            <th class='text-left pl-4' rowspan='2' style='min-width: 150px'>
              {{ $t('drug_name') }}
              {{ $t('unit') }}
            </th>
            <th style='min-width: 200px' class='text-left pl-4' rowspan='2'>
              {{ $t('lot') }}
            </th>
            <th rowspan='2' class='text-left pl-4' style='min-width: 150px'>
              {{ $t('date') }}
            </th>
            <th rowspan='2' class='text-left pl-4' style='min-width: 120px'>
              {{ $t('a_number') }}
            </th>
            <th rowspan='2' class='text-left pl-4' style='min-width: 150px'>
              {{ $t('build_from') }}
            </th>
            <th colspan='4' class='text-center'>
              {{ $t('imported_goods') }}
            </th>
            <th colspan='4' class='text-center'>
              {{ $t('product_out') }}
            </th>
            <th colspan='5' class='text-center'>
              {{ $t('balance') }}
            </th>
          </tr>
          <tr style='height: 43px'>
            <th style='min-width: 150px'>
              {{ $t('cost_unit') }}
            </th>
            <th style='min-width: 180px'>
              {{ $t('sale_price') }}
            </th>
            <th style='min-width: 180px'>
              {{ $t('imported_goods') }}
            </th>
            <th style='min-width: 180px'>
              {{ $t('total_cost') }}
            </th>
            <th style='min-width: 180px'>
              {{ $t('transfer_out') }}
            </th>
            <th style='min-width: 180px'>
              {{ $t('total_cost') }}
            </th>
            <th style='min-width: 180px'>
              {{ $t('product_out') }}
            </th>
            <th style='min-width: 180px'>
              {{ $t('total_cost') }}
            </th>
            <th style='min-width: 180px'>
              {{ $t('suspend') }}
            </th>
            <th style='min-width: 180px'>
              {{ $t('imported_goods') }}
            </th>
            <th style='min-width: 180px'>
              {{ $t('total_cost_imports') }}
            </th>
            <th style='min-width: 180px'>
              {{ $t('balance') }}
            </th>
            <th style='min-width: 180px'>
              {{ $t('total_cost_balance') }}
            </th>
          </tr>
        </template>
        <template v-slot:[`item.number`]='{ item }'>
          <span class='font-weight-bold'>
            {{ item.number }}
          </span>
        </template>
        <template v-slot:[`item.drug_id`]='{ item }'>
          <span class='font-weight-bold'>
            {{ item.drug_id }}
          </span>
        </template>
        <template v-slot:[`item.drug_name`]='{ item }'>
          <span class='font-weight-bold'>
            {{ item.drug_name }}
          </span>
        </template>
        <template v-slot:[`item.date`]='{ item }'>
          <ConverseDate :date='item.date' />
        </template>
        <template v-slot:[`item.in_cost`]='{ item }'>
          <span :class="item.drug_id ? 'font-weight-bold primary--text' : ''">
            {{ item.in_cost }}
          </span>
        </template>
        <template v-slot:[`item.in_price`]='{ item }'>
          <span :class="item.drug_id ? 'font-weight-bold primary--text' : ''">
            {{ item.in_price }}
          </span>
        </template>
        <template v-slot:[`item.in_amount`]='{ item }'>
          <span :class="item.drug_id ? 'font-weight-bold primary--text' : ''">
            {{ item.in_amount }}
          </span>
        </template>
        <template v-slot:[`item.in_cost_sum`]='{ item }'>
          <span :class="item.drug_id ? 'font-weight-bold primary--text' : ''">
            {{ item.in_cost_sum }}
          </span>
        </template>
        <template v-slot:[`item.out_tranfer`]='{ item }'>
          <span :class="item.drug_id ? 'font-weight-bold primary--text' : ''">
            {{ item.out_tranfer }}
          </span>
        </template>
        <template v-slot:[`item.out_tranfer_sum`]='{ item }'>
          <span :class="item.drug_id ? 'font-weight-bold primary--text' : ''">
            {{ item.in_cost }}
          </span>
        </template>
        <template v-slot:[`item.out_amount`]='{ item }'>
          <span :class="item.drug_id ? 'font-weight-bold primary--text' : ''">
            {{ item.out_amount }}
          </span>
        </template>
        <template v-slot:[`item.out_amount_sum`]='{ item }'>
          <span :class="item.drug_id ? 'font-weight-bold primary--text' : ''">
            {{ item.out_amount_sum }}
          </span>
        </template>
        <template v-slot:[`item.remove_count`]='{ item }'>
          <span :class="item.drug_id ? 'font-weight-bold primary--text' : ''">
            {{ item.remove_count }}
          </span>
        </template>
        <template v-slot:[`item.balance_in`]='{ item }'>
          <span :class="item.drug_id ? 'font-weight-bold primary--text' : ''">
            {{ item.balance_in }}
          </span>
        </template>
        <template v-slot:[`item.balance_in_cost_sum`]='{ item }'>
          <span :class="item.drug_id ? 'font-weight-bold primary--text' : ''">
            {{ item.balance_in_cost_sum }}
          </span>
        </template>
        <template v-slot:[`item.balance_amount`]='{ item }'>
          <span :class="item.drug_id ? 'font-weight-bold primary--text' : ''">
            {{ item.balance_amount }}
          </span>
        </template>
        <template v-slot:[`item.balance_cost_sum`]='{ item }'>
          <span :class="item.drug_id ? 'font-weight-bold primary--text' : ''">
            {{ item.balance_cost_sum }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import Comeback from '../Comeback.vue'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import MovementType from '@/@fake-db/data/MovementType.json'
import { i18n } from '@/plugins/i18n'
import { reportMovementDrug2, removeComma } from '../useExcel'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { sumdate } from '@/plugins/filters'
import moment from 'moment/moment'

export default {
  components: {
    Comeback,
    DateFilters,
    ConverseDate,
  },
  setup() {
    const XLSX = require('xlsx')
    const moment = require('moment')
    const movementSelect = ref('')
    const searchtext = ref('')
    const searchlot = ref('')
    const movementList = ref(MovementType.data)
    const dataTableList = ref([])
    const options = ref({})
    const loading = ref(true)
    const columns = ref([
      {
        text: '#',
        value: 'number',
        width: 100,
        align: 'start',
      },
      {
        text: i18n.t('drug_code'),
        value: 'drug_id',
        width: 120,
        align: 'start',
      },
      {
        text: i18n.t('drug_name'),
        value: 'drug_name',
        width: 120,
        align: 'start',
      },
      {
        text: i18n.t('lot'),
        value: 'druglot_name',
        width: 200,
        align: 'start',
      },
      {
        text: i18n.t('date'),
        value: 'date',
        width: 120,
        align: 'start',
      },
      {
        text: i18n.t('a_number'),
        value: 'code',
        width: 120,
        align: 'start',
      },
      {
        text: i18n.t('build_from'),
        value: 'type_name',
        width: 120,
        align: 'start',
      },
      {
        text: i18n.t('cost_unit'),
        value: 'in_cost',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('sale_price'),
        value: 'in_price',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('imported_goods'),
        value: 'in_amount',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('total_cost'),
        value: 'in_cost_sum',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('transfer_out'),
        value: 'out_tranfer',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('total_cost'),
        value: 'out_tranfer_sum',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('product_out'),
        value: 'out_amount',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('total_cost'),
        value: 'out_amount_sum',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('suspend'),
        value: 'remove_count',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('imported_goods'),
        value: 'balance_in',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('total_cost_imports'),
        value: 'balance_in_cost_sum',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('balance'),
        value: 'balance_amount',
        width: 120,
        align: 'end',
      },
      {
        text: i18n.t('total_cost_balance'),
        value: 'balance_cost_sum',
        width: 120,
        align: 'end',
      },
    ])
    const exportLoading = ref(false)
    const payload = ref({})
    onMounted(() => {
      addPayload()
    })
    const addPayload = (start = moment(new Date()).format('YYYY-MM-DD'), end = moment(new Date()).format('YYYY-MM-DD')) => {
      loading.value = true
      payload.value = {
        start,
        end,
        type_id: movementSelect.value,
        searchtext1: searchtext.value,
        searchtext2: searchlot.value,
        lang: i18n.locale,
      }
      serachReport(payload.value)
    }

    const serachReport = async payload => {
      loading.value = true
      const { data } = await reportMovementDrug2(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const fileName = `${i18n.t('ReportSummaryMovementsDrugEquipment')}.xlsx`
      const Heading = [
        [
          `${i18n.t('ReportSummaryMovementsDrugEquipment')} ${i18n.t('date')} ${sumdate(
            payload.value.start,
          )} - ${sumdate(payload.value.end)}`,
        ],
        [
          '#',
          i18n.t('drug_code'),
          i18n.t('drug_name'),
          i18n.t('lot'),
          i18n.t('date'),
          i18n.t('number'),
          i18n.t('build_from'),
          i18n.t('imported_goods'),
          '',
          '',
          '',
          i18n.t('product_out'),
          '',
          '',
          '',
          i18n.t('balance'),
          '',
          '',
          '',
        ],
        [
          '#',
          i18n.t('drug_code'),
          i18n.t('drug_name'),
          i18n.t('lot'),
          i18n.t('date'),
          i18n.t('number'),
          i18n.t('build_from'),
          i18n.t('cost_unit'),
          i18n.t('sale_price'),
          i18n.t('imports'),
          i18n.t('total_cost'),
          i18n.t('transfer_out'),
          i18n.t('total_cost'),
          i18n.t('product_out'),
          i18n.t('total_cost'),
          i18n.t('suspend'),
          i18n.t('imports'),
          i18n.t('total_cost_imports'),
          i18n.t('balance'),
          i18n.t('total_cost_balance'),
        ],
      ]
      const dataTmp = JSON.parse(JSON.stringify(dataTableList.value))
      dataTmp.map(item => {
        item.date = item.date ? sumdate(item.date) : ''

        return item
      })

      // sumdate( item_detail.date )
      const ws = XLSX.utils.aoa_to_sheet(Heading)
      const dataExport = await removeComma(JSON.parse(JSON.stringify(dataTmp)))
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'drug_id',
          'drug_name',
          'druglot_name',
          'date',
          'code',
          'type_name',
          'in_cost',
          'in_price',
          'in_amount',
          'in_cost_sum',
          'out_tranfer',
          'out_tranfer_sum',
          'out_amount',
          'out_amount_sum',
          'remove_count',
          'balance_in',
          'balance_in_cost_sum',
          'balance_amount',
          'balance_cost_sum',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 19 } },
        { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
        { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
        { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
        { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },
        { s: { r: 1, c: 7 }, e: { r: 1, c: 10 } },
        { s: { r: 1, c: 11 }, e: { r: 1, c: 14 } },
        { s: { r: 1, c: 15 }, e: { r: 1, c: 19 } },
      ]
      const wscols = [
        { wch: 6 },
        { wch: 12 },
        { wch: 12 },
        { wch: 25 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 20 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('ReportSummaryMovementsDrugEquipment')} `.replace('/', '-'))

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      movementSelect,
      searchtext,
      searchlot,
      movementList,
      dataTableList,
      options,
      loading,
      exportLoading,
      payload,
      columns,
      exportExcel,
      addPayload,
      mdiFileExcelOutline,
    }
  },
}
</script>
<style lang='scss'>
.theme--light .bg-b1 {
  background-color: #e0e0e0;
}

.theme--light .bg-b2 {
  background-color: #eeeeee;
}

.theme--light .bg-b3 {
  background-color: #f5f5f5;
}

.theme--dark .bg-b1 {
  background-color: #3b355a;
}

.theme--dark .bg-b2 {
  background-color: #3d385a;
}

.theme--dark .bg-b3 {
  background-color: #3d3956f5;
}
</style>
